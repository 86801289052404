import Contact from "../../pages/Contact";
import Apropos from "../../pages/Apropos";
import Registrar from "../../pages/Registrar";
import Enregistrement from "../../pages/Enregistrement";
import Search from "../../pages/Search";
import Statistics from "../../pages/Statistics";
import Download from "../../pages/Download";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Navigate() {
  const location = useLocation();
  return (
    //<AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Apropos />} />
        <Route path="/registrar" element={<Registrar />} />
        <Route path="/registration" element={<Enregistrement />} />
        <Route path="/search" element={<Search />} />
        <Route
          exact
          path="/:count"
          validateParams={(params) => !isNaN(params.count)}
          element={<Statistics />}
        />
        <Route path="/docs" element={<Download />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    //</AnimatePresence>
  );
}

export default Navigate;
