import React from "react";
import { useTranslation } from "react-i18next";
import formPdf from "../../assets/document/formulaire.pdf";
import enFormPdf from "../../assets/document/applicationForm.pdf";
import chartePdf from "../../assets/document/charte.pdf";
import policyPdf from "../../assets/document/policy.pdf";
import {motion} from 'framer-motion';

const Download = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <header className="head bg-dark">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-light mt-0">{t("Download.title")}</h2>
              <hr className="divider divider-orange" />
            </div>
          </div>
        </div>
      </header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="downloadBox col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
              <div className="downloadCard card border-0 bg-transparent">
                <div className="card-body text-center p-5">
                  <h4 className="fw-bold text-uppercase mb-4">
                    {t("Download.subTitle1")}
                  </h4>
                  <p className="mb-4 text-secondary">
                    {t("Download.downloadText1")} 
                  </p>
                  <a
                    id="download"
                    href={currentLanguage==='en'?enFormPdf:formPdf}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{t("Registrar.download")}</span>
                    <span>PDF</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="downloadBox col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
              <div className="downloadCard card border-0 bg-transparent">
                <div className="card-body text-center p-5">
                  <h4 className="fw-bold text-uppercase mb-4">
                    {t("Download.subTitle2")}
                  </h4>
                  <p className="mb-4 text-secondary">
                    {t("Download.downloadText2")}
                  </p>
                  <a
                    id="download"
                    href={currentLanguage==='en'?policyPdf:chartePdf}
                    target="_blank"
                  >
                    <span>{t("Registrar.download")}</span>
                    <span>PDF</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Download;
