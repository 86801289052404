import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import * as formik from "formik";
import * as yup from "yup";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import { motion } from "framer-motion";

function Contact() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const { Formik } = formik;

  const schema = yup.object().shape({
    contactName: yup.string().required(t("Contact.requiredName")),
    email: yup.string().email().required(t("Contact.requiredEmail")),
    phoneNumber: yup
      .string()
      .matches(
        /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/g,
        t("Contact.invalidPhone")
      ),
    object: yup
      .string()
      .required(t("Contact.requiredObject"))
      .min(4, t("Contact.invalidObject")),
    content: yup
      .string()
      .required(t("Contact.requiredMsg"))
      .min(15, t("Contact.invalidMsg")),
    captcha: yup.string().required("Please enter captcha"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState("");

  const sendEmail = async (values) => {
    setIsLoading(true);
    if (error) setError(null);
    if (data) setData("");
    try {
      const result = await axios.post(`${baseURL}/email`, values);
      if (result.status === 200) {
        setData(result.data);
      } else {
        setError(result.data.detail);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  const captchaRef = useRef(null);
  const handleCaptcha = (values) => {
    const captcha = captchaRef.current.value;
    if (validateCaptcha(captcha)) {
      sendEmail(values);
    } else {
      setError(t("Contact.invalidCaptcha"));
    }
  };
  useEffect(() => {
    loadCaptchaEnginge(6, "#f4623a", "white");
  });
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <Header />
      <section style={{ padding: "4rem 0" }}>
        <div class="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="mt-0">{t("Contact.title")}</h2>
              <hr className="divider" />
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
            <div className="col-lg-6">
              <Formik
                validationSchema={schema}
                onSubmit={(values) => handleCaptcha(values)}
                initialValues={{
                  contactName: "",
                  email: "",
                  phoneNumber: "",
                  object: "",
                  content: "",
                  captcha: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="validationCustom1 ">
                      <FloatingLabel
                        controlId="floatingName"
                        label={t("Contact.fullName")}
                        className="mb-3"
                      >
                        <Form.Control
                          required
                          name="contactName"
                          type="text"
                          placeholder="Enter your name..."
                          value={values.contactName}
                          onChange={handleChange}
                          isInvalid={!!errors.contactName}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.contactName}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group controlId="validationCustom2">
                      <FloatingLabel
                        controlId="floatingEmail"
                        label={t("Contact.emailAdr")}
                        className="mb-3"
                      >
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="name@example.com"
                          required
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group controlId="validationCustom3">
                      <FloatingLabel
                        controlId="floatingPhone"
                        label={t("Contact.phoneNum")}
                        className="mb-3"
                      >
                        <Form.Control
                          name="phoneNumber"
                          type="tel"
                          placeholder="(123) 456-7890"
                          required
                          value={values.phoneNumber}
                          onChange={handleChange}
                          isInvalid={!!errors.phoneNumber}
                        />

                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.phoneNumber}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="validationCustom4">
                      <FloatingLabel
                        controlId="floatingObject"
                        label={t("Contact.object")}
                        className="mb-3"
                      >
                        <Form.Control
                          name="object"
                          type="text"
                          placeholder="Enter your object here ..."
                          required
                          value={values.object}
                          onChange={handleChange}
                          isInvalid={!!errors.object}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.object}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group controlId="validationCustom5">
                      <FloatingLabel
                        controlId="floatingMsg"
                        label={t("Contact.message")}
                        className="mb-3"
                      >
                        <Form.Control
                          name="content"
                          type="text"
                          placeholder="Enter your message here..."
                          style={{ height: "10rem" }}
                          as="textarea"
                          rows={4}
                          required
                          value={values.content}
                          onChange={handleChange}
                          isInvalid={!!errors.content}
                        ></Form.Control>

                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.content}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <LoadCanvasTemplate
                      reloadText={t("Contact.resetCaptcha")}
                      reloadColor="#f4623a"
                    />

                    <Form.Group controlId="validationCustom6 ">
                      <FloatingLabel
                        controlId="floatingCaptcha"
                        label={t("Contact.captcha")}
                        className="mb-3 mt-3"
                      >
                        <Form.Control
                          required
                          name="captcha"
                          type="text"
                          ref={captchaRef}
                          placeholder="Enter your captcha..."
                          value={values.captcha}
                          onChange={handleChange}
                          isInvalid={!!errors.captcha}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.captcha}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    {isLoading && (
                      <div className="col-sm-12  mb-3 text-center">
                        <img
                          src={require("../../assets/img/loading.gif")}
                          alt={t("Search.loading")}
                          className="center-block"
                        />
                      </div>
                    )}

                    {!isLoading && data && <Alert variant="info">{data}</Alert>}

                    {!isLoading && error && (
                      <div
                        class="alert alert-danger d-flex align-items-center"
                        role="alert"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                          viewBox="0 0 16 16"
                          role="img"
                          aria-label="Warning:"
                        >
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>{error}</div>
                      </div>
                    )}

                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-xl"
                        id="submitButton"
                        type="submit"
                      >
                        {t("Contact.submit")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default Contact;
