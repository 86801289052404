import React from "react";
import { useTranslation } from "react-i18next";

const DomainCard = (props) => {
  const { t } = useTranslation();
  return (
    <div class="card col-sm-12 mb-3" key={props.item.id}>
      <div class="card-body">
        <h5 class="card-title text-primary position-relative">
          {props.item.domainName}
          <span class="badge bg-primary p-2 ml-2">{props.item.registrar}</span>
        </h5>

        <h6 class="card-subtitle mb-2 text-muted">{props.item.orgName}</h6>
        <p class="card-text mt-3">
          <ul className="list-inline">
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.orgAddress")}
              </span>
              <span>{props.item.addressOrg}</span>
            </li>
          </ul>
          <h6 className="card-second-title text-primary">
            {t("DomainCard.admTitle")}
          </h6>

          <ul className="list-inline">
            <li>
              <i className="bi bi-building card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.org")}:
              </span>
              <span>{props.item.orgNameAdm}</span>
            </li>
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.address")}:
              </span>
              <span>{props.item.addressAdm}</span>
            </li>
            <li>
              <i className="bi bi-person card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.contact")}:
              </span>
              <span>{props.item.contactAdm}</span>
            </li>
            <li>
              <i className="bi bi-envelope card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.email")}:
              </span>
              <span>{props.item.emailAdm}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.phone")}:
              </span>
              <span>{props.item.phoneAdm}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.fax")}:
              </span>
              <span>{props.item.faxAdm}</span>
            </li>
          </ul>
          <h6 className="card-second-title text-primary">
            {t("DomainCard.techTitle")}
          </h6>

          <ul className="list-inline">
            <li>
              <i className="bi bi-building card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.org")}:
              </span>
              <span>{props.item.orgNameTech}</span>
            </li>
            <li>
              <i className="bi bi-geo-alt card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.address")}:
              </span>
              <span>{props.item.addressTech}</span>
            </li>
            <li>
              <i className="bi bi-person card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.contact")}:
              </span>
              <span>{props.item.contactTech}</span>
            </li>
            <li>
              <i className="bi bi-envelope card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.email")}:
              </span>
              <span>{props.item.emailTech}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.phone")}:
              </span>
              <span>{props.item.phoneTech}</span>
            </li>
            <li>
              <i className="bi bi-telephone card-icon text-primary"></i>
              <span className="text-muted card-label">
                {t("DomainCard.fax")}:
              </span>
              <span>{props.item.faxTech}</span>
            </li>
          </ul>
        </p>
      </div>
      <div className="card-footer">
        <span className="text-muted card-label">{t("DomainCard.created")}:</span>
        {props.item.creationDate}
      </div>
    </div>
  );
};

export default DomainCard;
