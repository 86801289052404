import React, { useState } from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "../../assets/css/menu.css";
import { useLocation } from "react-router-dom";
import { Dropdown, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t, i18n } = useTranslation();
  const [shrinkNav, setShrinkNav] = useState(false);
  const navBarShrink = () => {
    if (window.scrollY >= 80) {
      setShrinkNav(true);
    } else {
      setShrinkNav(false);
    }
  };
  window.addEventListener("scroll", navBarShrink);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const options = [
    {
      value: "en",
      label: "English",
    }, //en
    {
      value: "fr",
      label: "Français",
    }, //fr
  ];

  const [langLabel, setLangLabel] = useState(
    options.find((option) => option.value === i18n.language.substring(0, 2))
      .label
  );

  function handlclick(n) {
    setLangLabel(options[n].label);
    i18n.changeLanguage(options[n].value);
  }
  return (
    <nav
      className={
        shrinkNav
          ? "navbar navbar-expand-lg navbar-light fixed-top py-3 navbar-shrink"
          : "navbar navbar-expand-lg navbar-light fixed-top py-3"
      }
      id="mainNav"
    >
      <div className="container px-4 px-lg-5">
        <a className="navbar-brand" href="/">
          <img src={require("../../assets/img/logo.png")} alt="DZ NIC" />
        </a>
        <Dropdown as={ButtonGroup} className={shrinkNav ? "" : "not-shrink"}>
          <Dropdown.Toggle
            className="bg-transparent btn-outline-primary border-0"
            id="lng-dropdown"
          >
            {langLabel}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handlclick(0)}>
              {options[0].label}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handlclick(1)}>
              {options[1].label}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto my-2 my-lg-0">
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "about" ? "nav-link active" : "nav-link"
                }
                href="/about"
              >
                {t("Menu.about")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "registrar"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/registrar"
              >
                {t("Menu.registrar")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "registration"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/registration"
              >
                {t("Menu.registration")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "docs" ? "nav-link active" : "nav-link"
                }
                href="/docs"
              >
                {t("Services.docServiceTitle")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  splitLocation[1] === "contact"
                    ? "nav-link active"
                    : "nav-link"
                }
                href="/contact"
              >
                {t("Menu.contact")}
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href={process.env.REACT_APP_AR_NIC_URL}>
                مركزأسماءالنطاقات.الجزائر
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
