import React from "react";
import { useTranslation } from "react-i18next";
import Chart from "../../components/Chart";
import StatsList from "../../components/StatsList";
import { useState, useEffect } from "react";
import axios from "axios";
import Nav from "react-bootstrap/Nav";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Statistics = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const baseURL = process.env.REACT_APP_API_URL;
  const [statURL, setStatURL] = useState("registrars/stats");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [key, setKey] = useState("stat1");
  const { count } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, [statURL]);
  const loadData = async () => {
    setIsLoading(true);
    setData([]);
    setError(null);
    try {
      const result = await axios.get(`${baseURL}/${statURL}`, {
        validateStatus: () => {
          return true;
        },
      });
      if (result.status === 302) {
        setData(result.data);
      } else {
        setError(result.data.detail);
      }
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
    setIsLoading(false);
  };

  if (isNaN(count)) {
    navigate({
      pathname: "/not-found",
    });
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <header className="head bg-dark">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center text-uppercase">
              <h1 className="mr-2 text-light">{count}</h1>
              <span className="text-light">{t("Statistics.totalDomains")}</span>
              <hr className="divider divider-orange" />
            </div>
          </div>
        </div>
      </header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-2 px-lg-5">
          <Nav
            fill
            variant="tabs"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              k === "stat1"
                ? setStatURL("registrars/stats")
                : setStatURL("domains/stats");
            }}
            id="stats-tab"
          >
            <Nav.Item>
              <Nav.Link eventKey="stat1">{t("Statistics.subTitle1")}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="stat2">{t("Statistics.subTitle2")}</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="row m-5">
            <div className="col-sm-12 col-md-6 section mb-5">
              {!isLoading && error && (
                <div
                  class="alert alert-danger d-flex align-items-center"
                  role="alert"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <div>{error}</div>
                </div>
              )}
              {isLoading && (
                <div className="text-center" style={{ width: "100%" }}>
                  <img
                    src={require("../../assets/img/loading.gif")}
                    alt={t("Search.loading")}
                    className="center-block"
                  />
                </div>
              )}
              {!isLoading && <Chart data={data} />}
            </div>
            <div className="col-sm-12 col-md-6 section">
              {!isLoading && (
                <StatsList
                  data={data}
                  titles={[
                    key === "stat1"
                      ? t("Registrar.regName")
                      : t("Statistics.year"),
                    t("Statistics.domainCreated"),
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Statistics;
