import React, { useState, useEffect } from "react";
import "../../assets/css/registrar.css";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import formPdf from "../../assets/document/RegistrarForm.pdf";
import { motion } from "framer-motion";

const Registrar = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const sections = document.querySelectorAll(".section");
    let active = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 300;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const titles = [t("Registrar.order"), t("Registrar.regName")];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <header className="head bg-dark">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-light mt-0">{t("Registrar.title")}</h2>
              <hr className="divider divider-orange" />
            </div>
          </div>
        </div>
      </header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="col-md-3 mb-5" id="leftCol">
              <ul className="nav nav-stacked sticky-top" id="sidebar">
                <li className="w-100">
                  <a
                    href="#sec1"
                    className={activeSection === "sec1" ? "selected" : ""}
                  >
                    {t("Registrar.sideMenu1")}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec2"
                    className={activeSection === "sec2" ? "selected" : ""}
                  >
                    {t("Registrar.sideMenu2")}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec3"
                    className={activeSection === "sec3" ? "selected" : ""}
                  >
                    {t("Registrar.sideMenu3")}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-9" id="rightCol">
              <div className="row">
                <div className="col-sm-12 mb-5 section" id="sec1">
                  <h2 className="mb-5">{t("Registrar.section1Title")}</h2>
                  <div className="text-muted">
                    <p className="text-justify">
                      {t("Registrar.section1Paragraph")}
                    </p>
                    <ol type="1">
                      <li className="py-2">{t("Registrar.condition1")}</li>
                      <li className="py-2">{t("Registrar.condition2")}</li>
                      <li className="py-2">{t("Registrar.condition3")}</li>
                      <li className="py-2 text-end">
                        {t("Registrar.condition4")}
                      </li>
                      <li className="py-2">{t("Registrar.condition5")}</li>
                      <li className="py-2 text-end">
                        {t("Registrar.condition6")}
                      </li>
                    </ol>
                  </div>
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 mb-5 section" id="sec2">
                  <h2 className="mb-5">{t("Registrar.section2Title")}</h2>
                  <Table titles={titles} />
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 section" id="sec3">
                  <h2 className="mb-5">{t("Registrar.section3Title")}</h2>
                  <p className="text-secondary">
                    {t("Registrar.downloadText")}
                  </p>
                  <div className="text-center">
                    <a
                      id="download"
                      href={formPdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{t("Registrar.download")}</span>
                      <span>PDF</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Registrar;
