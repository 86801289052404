import { useTranslation } from "react-i18next";
function Header() {
  const { t } = useTranslation();
  return (
    <header className="head bg-dark">
      <div className="container px-4 px-lg-5 h-100">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-lg-4">
            <div className="box">
              <div className="icon">
                <div className="image">
                  <i className="bi bi-envelope" aria-hidden="true"></i>
                </div>
                <div className="info">
                  <h3 className="title text-uppercase">
                    {t("Contact.email")} {t("Contact.and")}
                    {t("Contact.website")}
                  </h3>
                  <p>
                    <i className="bi bi-envelope" aria-hidden="true"></i>{" "}
                    dznic@nic.dz
                    <br />
                    <br />
                    <i className="bi bi-globe" aria-hidden="true"></i>{" "}
                    www.nic.dz
                  </p>
                </div>
              </div>
              <div className="space"></div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-lg-4">
            <div className="box">
              <div className="icon">
                <div className="image">
                  <i className="bi bi-phone" aria-hidden="true"></i>
                </div>
                <div className="info">
                  <h3 className="title text-uppercase">
                    {t("Contact.contacts")}
                  </h3>
                  <p>
                    <i className="bi bi-phone" aria-hidden="true"></i>{" "}
                    {t("Contact.adm")}: +213 023 25 53 95
                    <br />
                    <br />
                    <i className="bi bi-phone" aria-hidden="true"></i>{" "}
                    {t("Contact.tech")}: +213 023 25 54 08
                  </p>
                </div>
              </div>
              <div className="space"></div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-4">
            <div className="box">
              <div className="icon">
                <div className="image">
                  <i className="bi bi-geo-alt" aria-hidden="true"></i>
                </div>
                <div className="info">
                  <h3 className="title text-uppercase">
                    {t("Contact.address")}
                  </h3>
                  <p>
                    <i className="bi bi-geo-alt" aria-hidden="true"></i> 05,
                    Chemin des trois frères Aissou Ben Aknoun, BP 143, 16030
                    Alger Algérie
                  </p>
                </div>
              </div>
              <div className="space"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
