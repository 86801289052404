import Service from "../Service";
import { useTranslation } from "react-i18next";

function Services() {
  const { t } = useTranslation();
  return (
    <section className="page-section" id="services">
      <div className="container px-4 px-lg-5">
        <h2 className="text-center mt-0">{t("Services.title")}</h2>
        <hr className="divider" />
        <div className="row gx-3 gx-lg-5">
          <Service
            iconName="bi-people"
            title={t("Services.registrarServiceTitle")}
            text={t("Services.registrarServiceText")}
            path="/registrar"
          />
          <Service
            iconName="bi-floppy "
            title={t("Services.registrationServiceTitle")}
            text={t("Services.registrationServiceText")}
            path="/registration"
          />
          <Service
            iconName="bi-folder2-open "
            title={t("Services.docServiceTitle")}
            text={t("Services.docServiceText")}
            path="/docs"
          />
        </div>
      </div>
    </section>
  );
}

export default Services;
