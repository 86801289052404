import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Apropos() {
  const { t } = useTranslation();
  return (
    <motion.div
     initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <header className="head bg-dark">
        <div className="container px-4 px-lg-5 h-100">
          <div class="row gx-5 align-items-center">
            <div class="col-lg-6 order-lg-2 d-none d-md-block">
              <div class="p-5">
                <img
                  class="img-fluid"
                  src={require("../../assets/img/whoarewe.jpg")}
                  alt="..."
                />
              </div>
            </div>
            <div class="col-lg-6 order-lg-1">
              <div class="p-5 text-light">
                <h2 class="display-4">{t("About.firstTitle")}</h2>
                <div class=" text-justify text-white-75">
                  <p>
                    <strong className="text-primary">{t("About.dzNic")}</strong>
                    {t("About.section1Paragraph1a")}
                    <strong className="text-primary">{t("About.ccTld")}</strong>
                    {t("About.section1Paragraph1b")}
                  </p>
                  <p>
                    {t("About.section1Paragraph2a")}
                    <strong className="text-primary">{t("About.dz1")}</strong>
                    {t("About.section1Paragraph2b")}
                    <strong className="text-primary">
                      {t("About.cerist")}
                    </strong>
                    {t("About.section1Paragraph2c")}
                    <strong className="text-primary">{t("About.icann")}</strong>
                    {t("About.section1Paragraph2d")}
                  </p>
                  <p>
                    {t("About.section1Paragraph3a")}
                    <strong className="text-primary">{t("About.dz1")}</strong>
                    {t("About.section1Paragraph3b")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section>
        <div class="container px-4 px-lg-5">
          <div class="row gx-5 align-items-center">
            <div class="col-lg-6 d-none d-md-block">
              <div class="p-5">
                <img
                  class="img-fluid"
                  src={require("../../assets/img/cerist.jpg")}
                  alt="..."
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <h2 class="display-4">{t("About.secondTitle")}</h2>
                <div class=" text-justify text-muted">
                  <p>
                    {t("About.section2Paragraph1")}
                    <strong className="text-primary">{t("About.dz2")}</strong>.
                  </p>
                  <p>
                    {t("About.section2Paragraph2a")}
                    <strong className="text-primary">
                      {t("About.nicWebsite")}
                    </strong>{" "}
                    {t("About.section2Paragraph2b")}
                    <strong className="text-primary">
                      {t("About.nicWebsite")}
                    </strong>
                    {t("About.section2Paragraph2c")}
                  </p>
                  <p>
                    {t("About.section2Paragraph2d")}
                    <strong className="text-primary">
                      <a href="https://registrar.nic.dz">registrar.nic.dz</a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
}

export default Apropos;
