import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Enregistrement = () => {
  const { t } = useTranslation();

  const [activeSection, setActiveSection] = useState(null);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    const sections = document.querySelectorAll(".section");
    let active = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 300;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        active = section.id;
      }
    });

    setActiveSection(active);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <header className="head bg-dark">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="text-light mt-0">{t("Registration.title")}</h2>
              <hr className="divider divider-orange" />
            </div>
          </div>
        </div>
      </header>
      <section style={{ padding: "4rem 0" }}>
        <div className="container px-4 px-lg-5">
          <div className="row">
            <div className="col-md-3 mb-5" id="leftCol">
              <ul className="nav nav-stacked sticky-top" id="sidebar">
                <li className="w-100">
                  <a
                    href="#sec1"
                    className={activeSection === "sec1" ? "selected" : ""}
                  >
                    {t("Registration.sideMenu1")}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec2"
                    className={activeSection === "sec2" ? "selected" : ""}
                  >
                    {t("Registration.sideMenu2")}
                  </a>
                </li>
                <li className="w-100">
                  <a
                    href="#sec3"
                    className={activeSection === "sec3" ? "selected" : ""}
                  >
                    {t("Registration.sideMenu3")}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-9" id="rightCol">
              <div className="row">
                <div className="col-sm-12 mb-5 section" id="sec1">
                  <h2 className="mb-5">{t("Registration.section1Title")} :</h2>
                  <div className=" text-justify text-muted">
                    <p>{t("Registration.section1Paragraph")}</p>
                    <ul>
                      <li className="py-2">
                        <a href="/docs">{t("Registration.section1Doc1a")}</a>
                        {t("Registration.section1Doc1b")}
                      </li>
                      <li className="py-2">
                        {t("Registration.section1Doc2")}
                        <ul>
                          <li className="py-2">
                            {t("Registration.section1Doc2a")}
                          </li>
                          <li className="py-2">
                            {t("Registration.section1Doc2b")}
                          </li>
                          <li className="py-2">
                            {t("Registration.section1Doc2c")}
                          </li>
                        </ul>
                      </li>
                      <li className="py-2">
                        {t("Registration.section1Doc3a")}
                        <strong className="text-black">
                          {t("Registration.section1Doc3b")}
                        </strong>
                        {t("Registration.section1Doc3c")}
                      </li>
                    </ul>
                  </div>
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 mb-5 section" id="sec2">
                  <h2 className="mb-5">{t("Registration.section2Title")}:</h2>
                  <div className=" text-justify text-muted">
                    <p>{t("Registration.section2Paragraph1")}</p>
                    <ul>
                      <li className="py-2">{t("Registration.section2Doc1")}</li>
                      <li className="py-2">{t("Registration.section2Doc2")}</li>
                    </ul>
                    <p>{t("Registration.section2Paragraph2")}</p>
                    <p>{t("Registration.section2Paragraph3")}</p>
                    <div class="alert alert-warning">
                      <h4 class="alert-heading">
                        {t("Registration.important")}!
                      </h4>
                      <p>{t("Registration.section2Note")}</p>
                    </div>
                  </div>
                </div>
                <hr className="separation mb-5" />
                <div className="col-sm-12 section" id="sec3">
                  <h2 className="mb-5">{t("Registration.section3Title")} :</h2>
                  <div className=" text-justify text-muted mb-5">
                    <h5 className="text-primary">
                      {t("Registration.section3subTitle1")}
                    </h5>
                    <p>{t("Registration.section3Paragraph1")}</p>
                  </div>

                  <div className=" text-justify text-muted">
                    <h5 className="text-primary">
                      {t("Registration.section3subTitle2")}
                    </h5>
                    <p>{t("Registration.section3Paragraph2")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Enregistrement;
