import React from "react";

const StatsList = ({ data, titles }) => {
  return (
    <div class="table-responsive custom-table-responsive">
      <table class="table custom-table">
        <thead>
          <tr>
            {titles.map((title, index) => (
              <th scope="col" key={index}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <>
              <tr>
                <td>{item.label}</td>
                <td>{item.value}</td>
              </tr>
              <tr class="spacer">
                <td colspan="100"></td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StatsList;
