import { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Stat = () => {
  const { t } = useTranslation();
  const [counterOn, setCounterOn] = useState(false);
  const [data, setData] = useState(0);
  const baseURL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get(`${baseURL}/domains/count`, {
        validateStatus: () => {
          return true;
        },
      });
      if (result.status === 200) {
        setData(result.data);
      }
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };
  return (
    <section className="page-section bg-orange">
      <div className="container px-4 px-lg-5">
        <div className="row gx-4 gx-lg-5 justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-white mt-0">{t("Stats.title")}</h2>
            <hr className="divider divider-light" />
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <p className="text-white-75 mb-4 display-3">
                {counterOn && (
                  <CountUp start={0} end={data} duration={2} delay={0} />
                )}
              </p>
            </ScrollTrigger>
            <a className="btn btn-white btn-xl" href={"/" + data}>
              {t("Stats.btnText")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stat;
