import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import "./assets/css/custom.min.css";
import i18n from "./i18n";
import Navigate from "./components/Navigate";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router basename="/">
      <Menu />
      <I18nextProvider i18n={i18n}>
        <Navigate />
        <Footer />
      </I18nextProvider>
    </Router>
  </React.StrictMode>
);
