import "../../assets/css/masthead.css";
import Masthead from "../../components/Masthead";
import Stat from "../../components/Stat";
import Services from "../../components/Services";


function Home() {
  return (
   <>
      <Masthead />
      <Services />
      <Stat />
      </>
  );
}

export default Home;
